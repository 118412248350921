/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { DialogRoute } from '@app/common/modules/gmc-dialog';
import { StripeKeyGuard } from '@app/core/guards/stripe-key.guard';
export const DIALOG_UPDATE_CREDIT_CARD_ID = 'dialog-update-credit-card';

export const updateCardRoute = {
  path: 'update-card',
  loadComponent: () => import('./dialog-update-credit-card.component'),
  canActivate: [StripeKeyGuard],
  data: {
    // Same as in the design mockup, important for the extra wide form to fit
    dialogConfig: { width: '953px', autoFocus: false, id: DIALOG_UPDATE_CREDIT_CARD_ID },
  },
} as DialogRoute;
