/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { StripeService } from 'ngx-stripe';
import { Observable, of } from 'rxjs';
import { catchError, mapTo, tap } from 'rxjs/operators';

@Injectable()
export class StripeKeyGuard {
  constructor(
    private stripeService: StripeService,
    private http: HttpClient,
    private router: Router,
  ) {}
  canActivate() {
    return this.stripeService.key ? true : this.loadKey();
  }
  private loadKey(): Observable<true | UrlTree> {
    return this.http.get<{ STRIPE_PUBLISHABLE_KEY: string }>('assets/args.json').pipe(
      tap({
        next: (args) => this.stripeService.setKey(args.STRIPE_PUBLISHABLE_KEY),
        error: (err) => console.error('Failed to fetch Stripe key', err),
      }),
      mapTo(true as const),
      catchError(() => of(this.router.createUrlTree(['/billing']))),
    );
  }
}
